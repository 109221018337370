import React from 'react';

import { observer } from 'mobx-react-lite';

import { SERVICE_NAME } from 'constants/global';
import { PROVIDER_DUCALIS } from 'constants/Providers';

import CellWrapper from 'components/CellWrapper';
import DarkTooltip from 'components/DarkTooltip';
import PlatformIcon from 'components/PlatformIcon';

function SourceCell({ issue }) {
    let tooltip;
    if (issue.provider === PROVIDER_DUCALIS) {
        tooltip = `${SERVICE_NAME} issue`;
    } else {
        tooltip = (
            <>
                External issue from{' '}
                <span className="t-cap">
                    {issue.provider} ({issue.board?.platform?.name})
                </span>
            </>
        );
    }

    return (
        <CellWrapper className="o-6">
            <DarkTooltip content={tooltip}>
                <PlatformIcon provider={issue.provider} />
            </DarkTooltip>
        </CellWrapper>
    );
}

export default observer(SourceCell);
