import React, { forwardRef } from 'react';

import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu';
import { styled, keyframes } from '@stitches/react';

import { Button } from 'components/Button';

const slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(-2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-2px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(2px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StDropdownMenuContent = styled(DropdownMenuPrimitive.Content, {
    minWidth: 220,
    backgroundColor: '$bg2',
    borderRadius: 4,
    padding: 4,
    border: '1px solid $grayA4',
    boxShadow: '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    willChange: 'opacity',
    '&[data-state="open"]': {
        '&[data-side="top"]': { animationName: slideDownAndFade },
        '&[data-side="right"]': { animationName: slideLeftAndFade },
        '&[data-side="bottom"]': { animationName: slideUpAndFade },
        '&[data-side="left"]': { animationName: slideRightAndFade },
    },
});
StDropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName;

const DropdownMenuItem = forwardRef(({ disabled, ...props }, ref) => (
    <DropdownMenuPrimitive.Item asChild disabled={disabled} ref={ref}>
        <Button {...props} minimal block disabled={disabled} css={{ outline: 'none !important' }} />
    </DropdownMenuPrimitive.Item>
));
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName;

const StDropdownMenuSeparator = styled(DropdownMenuPrimitive.Separator, {
    height: 1,
    backgroundColor: '$grayA4',
    margin: 5,
});
StDropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName;

const DropdownMenu = ({ children, content, side, onCloseAutoFocus, contentProps, align, ...props }) => {
    return (
        <DropdownMenuPrimitive.Root {...props}>
            <DropdownMenuPrimitive.Trigger asChild>{children}</DropdownMenuPrimitive.Trigger>

            <DropdownMenuPrimitive.Portal>
                <StDropdownMenuContent
                    onCloseAutoFocus={onCloseAutoFocus}
                    side={side}
                    align={align}
                    sideOffset={4}
                    {...contentProps}
                >
                    {content}
                </StDropdownMenuContent>
            </DropdownMenuPrimitive.Portal>
        </DropdownMenuPrimitive.Root>
    );
};

export { DropdownMenu, DropdownMenuItem, StDropdownMenuSeparator as DropdownMenuSeparator };
