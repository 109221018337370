import { CURRENT_USER_ID } from 'constants/global';
import React from 'react';

import { observer } from 'mobx-react-lite';

import { AlignmentTableRow } from 'pages/Board/AlignmentList/components/AlignmentTableRow';

import { utilsStore } from 'store/models/UtilsStore';

import { StLineThrough } from 'atoms/StLineThrough';

import ValueInput from 'components/MasterTable/components/CellAlignment/ValueInput';
import Tooltip from 'components/Tooltip';

function CriteriaValues({ issue, criteria, user, currentUserCriteriaIds, limit }) {
    const userVotes = issue.externalData?.votesUsersId.get(user.id)?.votes;
    const isCurrentUser = CURRENT_USER_ID === user.id;

    if (!issue.board || issue.skippedUsersIds.includes(user.id) || (limit && !userVotes && !isCurrentUser)) {
        return null;
    }

    const userCriteria = isCurrentUser
        ? currentUserCriteriaIds
        : issue.board.getCriteriaByUserId(user.id).map((criterion) => criterion.id);

    const disableEvaluation = issue.board.disableEvaluation;

    const criteriaValue = criteria.map((criterion) => {
        if (!criterion) return <td key={`${user.id}-${Date.now()}`} />;

        const emptyValue = userCriteria.includes(criterion.id) ? (
            <Tooltip side="top" content="Not evaluated yet">
                <div>{' - '}</div>
            </Tooltip>
        ) : (
            ''
        );

        const rewritten = issue.externalData && issue.externalData.finalVotes.has(criterion.id);
        const value = userVotes?.[criterion.id];
        const key = `${user.id}-${criterion.id}`;

        if (!rewritten && isCurrentUser && currentUserCriteriaIds.includes(criterion.id)) {
            return (
                <td key={key} className="p-r">
                    <ValueInput value={value ?? ''} criterion={criterion} disabled={disableEvaluation} />
                </td>
            );
        }

        return (
            <td key={key}>
                <div className="t-r">
                    {rewritten && value !== undefined ? <StLineThrough>{value}</StLineThrough> : (value ?? emptyValue)}
                </div>
            </td>
        );
    });

    return (
        <AlignmentTableRow slim={utilsStore.settings.alignmentSlim} key={user.id} user={user}>
            {criteriaValue}
        </AlignmentTableRow>
    );
}

export default observer(CriteriaValues);
