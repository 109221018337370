import amplitude from 'amplitude-js';
import { AMPLITUDE_KEY, ANALYTICS_CURRENT_USER_ID, CURRENT_USER_IS_ADMIN, INSTANCE_PREFIX } from 'constants/global';

const canSend = !CURRENT_USER_IS_ADMIN && AMPLITUDE_KEY;

export const initAmplitude = () => {
    if (canSend) {
        amplitude.getInstance().init(AMPLITUDE_KEY, ANALYTICS_CURRENT_USER_ID, {
            includeReferrer: true,
            saveEvents: true,
            includeUtm: true,
            apiEndpoint: window.amplitudeApiEndpoint || undefined,
        });
    }
};

export const sendAmplitudeData = (eventType, eventProperties) => {
    canSend && amplitude.getInstance().logEvent(eventType, eventProperties);
};

export const setUserOrganization = (id) => {
    const orgId = `${INSTANCE_PREFIX}${id}`;
    canSend && amplitude.getInstance().setGroup('orgId', orgId.toString());
};

/**
 *
 * @param {Object} props
 * @param {String} props.name
 * @param {String} props.orgName
 * @param {Boolean} props.owner
 * @param {Boolean} props.admin
 * @param {String} avatar
 * @param {String} email
 * @param {String} logo
 */
export const setUserProperties = (props, avatar, email, logo) => {
    canSend && amplitude.getInstance().setUserProperties(props);

    if (process.env.NODE_ENV !== 'production') {
        console.info('🫡setUserProperties', props, { avatar, email, logo });
    }
};
