import { SERVICE_NAMESPACE } from 'constants/global';
import { migrateLocalColumnConfigStorage } from 'utils/AppStorage/migrations';

import AppStorage from './AppStorage';

const appStorage = new AppStorage(SERVICE_NAMESPACE);

export default appStorage;

export { APP_STORAGE_KEYS } from './constants';

// TODO: remove after 1th October
migrateLocalColumnConfigStorage(appStorage);
