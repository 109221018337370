import { sendToSentry } from 'utils/sentry';

import { APP_STORAGE_KEYS } from './constants';

export const migrateLocalColumnConfigStorage = (appStorage) => {
    const data = appStorage.get(APP_STORAGE_KEYS.columns_width_old);
    try {
        data && appStorage.set(APP_STORAGE_KEYS.columns_width, convertOldColumnsConfig(data));
        appStorage.remove(APP_STORAGE_KEYS.columns_width_old);
    } catch (e) {
        sendToSentry('Fail migrateLocalColumnConfigStorage', { error: e, data });
    }
};

function convertOldColumnsConfig(data) {
    return Object.entries(data).reduce((result, [key, value]) => {
        result[key] = value.fields.reduce((res, item) => {
            res[item.id] = item.width;
            return res;
        }, {});
        return result;
    }, {});
}
