import { makeAutoObservable, observable } from 'mobx';

import { CURRENT_USER_ID } from 'constants/global';
import { VIEWS } from 'constants/Pages';

import { mainStore } from './MainStore';

export default class IssuesData {
    /** @type {number} */
    id = 0;

    /** @type {null|number} */
    alignment = null;

    /** @type {null|number} */
    usersAlignment = null;

    /** @type {Map<number, number>} */
    criteriaAlignmentIds = new Map();

    /** @type {Map<number, number>} */
    usersAlignmentIds = new Map();

    /** @type {boolean} */
    all_voted = false;

    /** @type {null|number} */
    total = null;

    /** @type {null|number} */
    voting_percent = 0;

    /** @type {string|number} */
    boardId = 0;

    /** @type {boolean} */
    unvoted = true;

    /** @type {boolean} */
    skipped = false;

    /** @type {boolean} */
    isUserNotAllVoted = false;

    /** @type {Map<number, number>} */
    userVotes = new Map();

    /** @type {Map<number, number>} */
    cr_weightless = new Map();

    /** @type {Map<number, number>} */
    finalVotes = new Map();

    /** @type {Map<number, number>} */
    votesUsersId = new Map();

    constructor(data) {
        makeAutoObservable(this, {
            criteriaAlignmentIds: observable.shallow,
            usersAlignmentIds: observable.shallow,
            userVotes: observable.shallow,
            cr_weightless: observable.shallow,
            finalVotes: observable,
            votesUsersId: observable.shallow,
        });

        data && this.fillModel(data, true);
    }

    /** @type {Array} */
    get votes() {
        return Array.from(this.votesUsersId.values());
    }

    fillModel({ cr_weightless, final_votes, alignment, users_alignment, votes, ...data }, force) {
        data && Object.assign(this, data);

        if (final_votes) {
            this.finalVotes.clear();
            final_votes.forEach((item) => {
                this.finalVotes.set(item.criterion_id, item);
            });
        }

        if (cr_weightless !== undefined) {
            this.cr_weightless.clear();
            cr_weightless &&
                Object.entries(cr_weightless).forEach(([criterionId, value]) => {
                    this.cr_weightless.set(Number(criterionId), value);
                });
        }

        if (alignment !== undefined) {
            this.alignment = alignment?.value ?? null;
            this.criteriaAlignmentIds.clear();
            (alignment?.data || []).forEach((item) => {
                this.criteriaAlignmentIds.set(item.criterion_id, item.value);
            });
        }

        if (users_alignment !== undefined) {
            this.usersAlignmentIds.clear();
            this.usersAlignment = users_alignment?.value ?? null;
            (users_alignment?.data || []).forEach((item) => {
                this.usersAlignmentIds.set(item.user_id, item.value);
            });
        }

        this.votesUsersId.clear();
        if (votes) {
            votes.forEach((userVote) => this.votesUsersId.set(userVote.user_id, userVote));
        }

        if (
            force ||
            document.visibilityState === 'hidden' ||
            ![VIEWS.EVALUATION, VIEWS.SCORES, VIEWS.FOCUS_MODE].includes(mainStore.page)
        ) {
            this.fillUserVotes(votes);
        }
    }

    dropUserVotesData() {
        this.userVotes.clear();
        this.unvoted = true;
        this.skipped = false;
        this.isUserNotAllVoted = true;
    }

    // TODO: https://concertwithme.atlassian.net/browse/DCLS-9507 - переделать на работу с get currentUserVotes()
    fillUserVotes(votes) {
        if (!votes) {
            return this.dropUserVotesData();
        }

        const currentUserVotes = votes.find((el) => el.user_id === CURRENT_USER_ID);

        if (!currentUserVotes?.votes) {
            this.userVotes.clear();
            const unvoted = currentUserVotes?.unvoted ?? true;
            this.unvoted = unvoted;
            this.skipped = currentUserVotes?.skipped ?? false;
            this.isUserNotAllVoted = unvoted;
            return;
        }

        if (Array.isArray(currentUserVotes.votes)) {
            return this.dropUserVotesData();
        }

        Object.entries(currentUserVotes.votes).forEach(([criterionId, value]) => {
            this.userVotes.set(Number(criterionId), value);
        });

        const unvoted = currentUserVotes.unvoted ?? true;
        this.unvoted = unvoted;
        this.skipped = currentUserVotes.skipped ?? false;
        this.isUserNotAllVoted = unvoted;
    }

    changeVote({ value, criterion }) {
        if (value === '') {
            this.userVotes.delete(criterion.id);
        } else {
            this.userVotes.set(criterion.id, Number(value));
        }
    }
}
