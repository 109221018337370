import React from 'react';

import { iconSvgPaths } from 'components/CustomIcon/components/iconSvgPaths';
import { CustomIconName } from 'components/CustomIcon/index';

export function renderSvgPaths(icon) {
    const pathStrings = iconSvgPaths[icon];
    if (!pathStrings) {
        return null;
    }

    const rules = {};

    if (
        [
            CustomIconName.YT,
            CustomIconName.SUBTASK,
            CustomIconName.STAR_EMPTY,
            CustomIconName.FACILITATOR,
            CustomIconName.POKER,
            CustomIconName.SAND_CLOCK,
            CustomIconName.SETTINGS,
            CustomIconName.BLOCKS,
            CustomIconName.BLOCKED_BY,
            CustomIconName.STOP_SIGN,
            CustomIconName.REFERENCED,
            CustomIconName.EYE,
            CustomIconName.EYE_SLASH,
            CustomIconName.GLOBE,
            CustomIconName.TRASH,
            CustomIconName.UNLINK,
            CustomIconName.SCORES,
            CustomIconName.CHANGELOG,
            CustomIconName.ALIGN_USERS,
            CustomIconName.MATRIX,
            CustomIconName.QUESTIONS,
            CustomIconName.COPY,
            CustomIconName.LOCKED,
            CustomIconName.UNLOCKED,
            CustomIconName.COLUMNS,
            CustomIconName.FOCUS_MODE,
            CustomIconName.USERS,
            CustomIconName.CRITERIA,
            CustomIconName.CARD,
            CustomIconName.ROBOT,
            CustomIconName.PLANE,
            CustomIconName.ENVELOP,
            CustomIconName.UPGRADE,
            CustomIconName.O_GRAPH,
            CustomIconName.WARNING,
            CustomIconName.ARCHIVE,
            CustomIconName.THUMB_UP,
            CustomIconName.THUMB_UP_SLASHED,
            CustomIconName.CLEAR,
            CustomIconName.CLEAR,
            CustomIconName.USER_PLUS,
            CustomIconName.COMMENT,
            CustomIconName.TAGS,
            CustomIconName.DRAFT,
            CustomIconName.DOWNLOAD,
            CustomIconName.TYPE,
            CustomIconName.KAITEN,
            CustomIconName.INBOX,
            CustomIconName.DOCUMENT,
            CustomIconName.FOLDER,
            CustomIconName.BULLHORN,
            CustomIconName.HALF_FILLED,
            CustomIconName.USER_PLUS_ROUND,
            CustomIconName.USER_MINUS_ROUND,
            CustomIconName.PANEL_LEFT,
            CustomIconName.PANEL_RIGHT,
        ].includes(icon)
    ) {
        rules.fillRule = 'evenodd';
        rules.clipRule = 'evenodd';
    }

    return pathStrings.map((d, i) => <path {...rules} key={i} d={d} />);
}
