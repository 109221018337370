import React from 'react';

import { observer } from 'mobx-react-lite';

import { utilsStore } from 'store/models/UtilsStore';

import AverageScore from './components/AverageScore';
import CriteriaValues from './components/CriteriaValues';
import FinalScoreTooltip from './components/FinalScoreTooltip';
import FormulaTooltip from './components/FormulaTooltip';
import SlimButton from './components/SlimButton';
import { FinalScoreTableHeader } from './FinalScoreTableHeader';
import FinalScoreTableSuperScore from './FinalScoreTableSuperScore';

export const ScoreTable = observer(({ issue }) => {
    if (!issue.board) return null;

    const limitUsers = utilsStore.settings.limitUsers;
    const criteria = issue.board.scoreTableCriteria;
    const currentUserCriteriaIds = issue.board.currentUserCriteria.map(({ id }) => id);
    let users;
    if (limitUsers) {
        users = issue.board.activeUsersWithCurrentUserCriteria;
    } else {
        users = issue.board.activeUsersWithCriteria;
    }

    const body = users.map((user) => (
        <CriteriaValues
            limit={limitUsers}
            key={user.id}
            criteria={criteria}
            issue={issue}
            currentUserCriteriaIds={currentUserCriteriaIds}
            user={user}
        />
    ));

    return (
        <table className="bp5-html-table bp5-small bp5-html-table--stripe bp5-html-table--center bp5-html-table--fixedleft">
            <thead>
                <tr>
                    <SlimButton currentUserInBoard={issue.board.currentUserInBoard} />
                    <FinalScoreTableHeader criteria={criteria} />
                </tr>
            </thead>
            <tbody>{body}</tbody>
            <tfoot>
                <tr>
                    <td>
                        <FormulaTooltip board={issue.board} />
                    </td>
                    <AverageScore issue={issue} criteria={criteria} />
                </tr>
                <tr>
                    <td>
                        <FinalScoreTooltip board={issue.board}>
                            <span className="t-w-lb t-nw">
                                {utilsStore.settings.alignmentSlim ? 'FS' : 'Final scores'}
                            </span>
                        </FinalScoreTooltip>
                    </td>
                    <FinalScoreTableSuperScore votes={issue.externalData?.finalVotes} criteria={criteria} />
                </tr>
            </tfoot>
        </table>
    );
});

ScoreTable.displayName = 'ScoreTable';
