import { forwardRef, memo } from 'react';

import { Slot } from '@radix-ui/react-slot';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { keyframes, styled } from 'stitches.config';

const slideUpAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(4px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(-4px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateY(-4px)' },
    '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
    '0%': { opacity: 0, transform: 'translateX(4px)' },
    '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StTooltipContent = styled(TooltipPrimitive.Content, {
    borderRadius: 4,
    padding: '4px 6px',
    border: '1px solid $grayA4',
    lineHeight: '16px',
    fontSize: 12,
    color: '$tc2',
    backgroundColor: '$bg3',
    boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
    userSelect: 'none',
    zIndex: 1000000,
    maxWidth: 420,
    overflow: 'auto',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',

    maxHeight: 'var(--radix-tooltip-content-available-height)',

    'a:not([data-btn="true"])': {
        color: 'inherit',
        textDecoration: 'underline',
    },

    '@media (prefers-reduced-motion: no-preference)': {
        willChange: 'opacity',
        animationDuration: '.3s',
        animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',

        '&[data-state="delayed-open"]': {
            '&[data-side="top"]': { animationName: slideDownAndFade },
            '&[data-side="right"]': { animationName: slideLeftAndFade },
            '&[data-side="bottom"]': { animationName: slideUpAndFade },
            '&[data-side="left"]': { animationName: slideRightAndFade },
        },
    },

    variants: {
        triggerWidth: {
            true: {
                width: 'var(--radix-tooltip-trigger-width)',
            },
        },
    },
});

const StDisabledWrapper = styled(Slot, {
    cursor: 'not-allowed',
    '&>*': {
        pointerEvents: 'none',
    },
});

export const TooltipProvider = forwardRef(({ children, ...props }, ref) => (
    <TooltipPrimitive.Provider delayDuration={400} ref={ref} {...props}>
        {children}
    </TooltipPrimitive.Provider>
));
export const TooltipRoot = TooltipPrimitive.Root;
export const TooltipTrigger = TooltipPrimitive.Trigger;
export const TooltipContent = forwardRef(({ sideOffset = 4, content, ...props }, ref) => {
    const contentElm = typeof content === 'string' ? <div dangerouslySetInnerHTML={{ __html: content }} /> : content;

    return (
        <TooltipPrimitive.Portal>
            <StTooltipContent ref={ref} sideOffset={sideOffset} {...props}>
                {contentElm}
            </StTooltipContent>
        </TooltipPrimitive.Portal>
    );
});
TooltipContent.displayName = TooltipPrimitive.Content.displayName;

/**
 * @typedef {'start'|'center'|'end'} ALIGN_TYPES
 * @typedef {'top'|'right'|'bottom'|'left'} SIDE_TYPES
 *
 * @param {JSX.Element|string} content
 * @param {JSX.Element|string} content
 * @param {JSX.Element|string} children
 * @param {Object} props
 * @param {ALIGN_TYPES} [align=center]
 * @param {SIDE_TYPES} [side=bottom]
 */
const Tooltip = memo(
    ({
        open,
        content,
        children,
        align,
        side = 'bottom',
        contentClassName,
        disableHoverableContent = true,
        ...props
    }) => {
        if (!content) return children;

        const trigger = children?.props?.disabled ? (
            <StDisabledWrapper tabIndex={0}>{children}</StDisabledWrapper>
        ) : (
            children
        );

        return (
            <TooltipRoot open={open} disableHoverableContent={disableHoverableContent}>
                <TooltipTrigger asChild>{trigger}</TooltipTrigger>
                <TooltipContent
                    sideOffset={4}
                    align={align}
                    side={side}
                    className={contentClassName}
                    content={content}
                    {...props}
                />
            </TooltipRoot>
        );
    },
);
Tooltip.displayName = 'Tooltip';

export default Tooltip;
