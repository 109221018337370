import React from 'react';

import { observer } from 'mobx-react-lite';

import { getSprintStatusText } from 'utils';

import { StButton } from 'atoms/StButton';

import HiddenContent from 'components/MasterTable/components/HiddenContent/HiddenContent';
import Tooltip from 'components/Tooltip';

function RowNumber({ issue, row_number }) {
    // TODO: https://concertwithme.atlassian.net/browse/DCLS-6798
    // const rowNumber = model?.considerBlockers ? issue.blockRowNumber : row_number || issue.row_number;

    if (!issue.board) {
        return null;
    }

    if (issue.board.hideScores) {
        return <HiddenContent revealAt={issue.board.revealDateString} tooltip="Nobody can see top priority until" />;
    }

    if (!row_number) {
        return null;
    }

    if (!issue.sprint) {
        return (
            <div>
                <StButton size={24} as="div" color="none" css={{ minWidth: 0, padding: '0 4px' }}>
                    {row_number}
                </StButton>
            </div>
        );
    }

    return (
        <Tooltip content={getSprintStatusText(issue.sprint)} side="top">
            <StButton size={24} as="div" color={issue.sprintColor} css={{ minWidth: 0, padding: '0 4px' }}>
                {row_number}
            </StButton>
        </Tooltip>
    );
}

export default observer(RowNumber);
