import { deleteDB } from 'idb';

import { CURRENT_ORG_ID, CURRENT_USER_ID, INSTANCE_PREFIX } from 'constants/global';
import { sendToSentry } from 'utils';

import { updateUserDB, USER_DB_VERSION } from 'store/updateUserDB';

import debugLog from 'utils/debugLog';
import delay from 'utils/delay';
import logEvent from 'utils/logEvent';

import { mainStore } from './models/MainStore';
import { openButRejectOnBlocking } from './openButRejectOnBlocking';
import { ORG_DB_STORES, ORG_DB_VERSION, updateOrgDB } from './updateOrgDB';

export default class IndexDb {
    dbName = INSTANCE_PREFIX || 'ducalis_';
    userDBNameVersion = 2;
    orgDBNameVersion = 9;
    transactionOptions = { durability: 'relaxed' };

    orgDb;
    userDb;

    async clearOldDBs(userId, orgId) {
        try {
            if (typeof indexedDB.databases === 'function') {
                const bases = await indexedDB.databases();

                const basesNames = bases.map((el) => el.name);

                if (basesNames.includes(`ducalis_v7_o_${orgId}`)) await deleteDB(`ducalis_v7_o_${orgId}`);
                if (basesNames.includes(`ducalis_v8_o_${orgId}`)) await deleteDB(`ducalis_v8_o_${orgId}`);
            } else {
                await deleteDB(`ducalis_v7_o_${orgId}`);
                await deleteDB(`ducalis_v8_o_${orgId}`);
            }
        } catch (e) {
            console.error('Fail clear old DB', e);
            sendToSentry('Fail clear old DB', { error: e });
        }
    }

    openUserDb = async () => {
        if (this.userDb) return this.userDb;

        const dbName = `${this.dbName}v${this.userDBNameVersion}_u_${CURRENT_USER_ID}`;
        this.userDb = await openButRejectOnBlocking(dbName, USER_DB_VERSION, updateUserDB, () => {
            mainStore.db.userDb.close();
            document.visibilityState === 'hidden' && window.location.reload();
        });

        return this.userDb;
    };

    openOrgDB = async () => {
        if (this.orgDb) return this.orgDb;

        const dbName = `${this.dbName}v${this.orgDBNameVersion}_o_${CURRENT_ORG_ID}`;

        this.orgDb = await openButRejectOnBlocking(dbName, ORG_DB_VERSION, updateOrgDB, () => {
            mainStore.db.orgDb.close();
            document.visibilityState === 'hidden' && window.location.reload();
        });
        return this.orgDb;
    };

    async runUserDB(userId, orgId) {
        await this.openUserDb();
        const organization = await this.getOrganization(orgId);

        if (organization) {
            mainStore.organization.updateModel(organization);
            return true;
        }
        return false;
    }

    getFullData = async () => {
        const trans = this.orgDb.transaction([
            'platforms',
            'boards',
            'votedPercents',
            'reports',
            'users',
            'issues',
            'issuesScore',
            'ideas',
            'comments',
            'IssueStatus',
            'IdeaLabel',
            'IdeaStatus',
            'IssueLabel',
            'IssueType',
            'IdeaAnnounceLabel',
            'issuesLinks',
            'announces',
            ORG_DB_STORES.criteria,
            ORG_DB_STORES.criteriaBoards,
            ORG_DB_STORES.releaseNotes,
        ]);

        const usersStore = trans.objectStore('users');
        const platformsStore = trans.objectStore('platforms');
        const boardsStore = trans.objectStore('boards');
        const votedPercentsStore = trans.objectStore('votedPercents');
        const reportsStore = trans.objectStore('reports');
        const issuesStore = trans.objectStore('issues');
        const issuesScoreStore = trans.objectStore('issuesScore');
        const ideasStore = trans.objectStore('ideas');
        const commentsStore = trans.objectStore('comments');
        const IssueStatusStore = trans.objectStore('IssueStatus');
        const IdeaLabelStore = trans.objectStore('IdeaLabel');
        const IdeaStatusStore = trans.objectStore('IdeaStatus');
        const IssueLabelStore = trans.objectStore('IssueLabel');
        const IssueTypeStore = trans.objectStore('IssueType');
        const IdeaAnnounceLabelStore = trans.objectStore('IdeaAnnounceLabel');
        const issuesLinksStore = trans.objectStore('issuesLinks');
        const announcesStore = trans.objectStore('announces');
        const criteriaStore = trans.objectStore(ORG_DB_STORES.criteria);
        const criteriaBoardsStore = trans.objectStore(ORG_DB_STORES.criteriaBoards);
        const releaseNotesStore = trans.objectStore(ORG_DB_STORES.releaseNotes);

        const [
            boards,
            votedPercents,
            reports,
            platforms,
            users,
            issues,
            issuesScore,
            ideas,
            comments,
            IssueStatus,
            IdeaLabel,
            IdeaStatus,
            IssueLabel,
            IssueType,
            IdeaAnnounceLabel,
            issuesLinks,
            announces,
            criteria,
            criteriaBoards,
            releaseNotes,
        ] = await Promise.all([
            boardsStore.getAll(),
            votedPercentsStore.getAll(),
            reportsStore.getAll(),
            platformsStore.getAll(),
            usersStore.getAll(),
            issuesStore.getAll(),
            issuesScoreStore.getAll(),
            ideasStore.getAll(),
            commentsStore.getAll(),
            IssueStatusStore.getAll(),
            IdeaLabelStore.getAll(),
            IdeaStatusStore.getAll(),
            IssueLabelStore.getAll(),
            IssueTypeStore.getAll(),
            IdeaAnnounceLabelStore.getAll(),
            issuesLinksStore.getAll(),
            announcesStore.getAll(),
            criteriaStore.getAll(),
            criteriaBoardsStore.getAll(),
            releaseNotesStore.getAll(),
        ]);

        if (platforms && users.length > 0) {
            return {
                boards,
                reports,
                platforms,
                users,
                issues,
                ideas,
                issuesScore,
                votedPercents,
                comments,
                IssueStatus,
                IdeaLabel,
                IdeaStatus,
                IssueLabel,
                IssueType,
                IdeaAnnounceLabel,
                issuesLinks,
                announces,
                criteria,
                criteriaBoards,
                releaseNotes,
            };
        }
        return false;
    };

    async runOrgDB(hasOrg) {
        await this.openOrgDB();
        if (!hasOrg) return undefined;

        return await this.getFullData();
    }

    startDB = async (userId, orgId) => {
        try {
            process.env.REACT_APP_ENV !== 'prod' && debugLog('START > startDB > 1');
            const hasOrg = await this.runUserDB(userId, orgId);
            process.env.REACT_APP_ENV !== 'prod' && debugLog('START > startDB > 2');
            const dataDb = await this.runOrgDB(hasOrg);
            process.env.REACT_APP_ENV !== 'prod' && debugLog('START > startDB > 3');
            delay(0).then(() => this.clearOldDBs(userId, orgId));
            return dataDb;
        } catch (error) {
            return await Promise.reject(error);
        }
    };

    async dropDB() {
        await this.saveMeta(0);
        const dbNameUser = `${this.dbName}u_${CURRENT_USER_ID}`;
        const dbNameOrg = `${this.dbName}o_${CURRENT_ORG_ID}`;
        await Promise.all([deleteDB(dbNameUser), deleteDB(dbNameOrg)]);
        logEvent('drop DB');
    }

    getData = async ({ fieldName, index, query }) => {
        const orgDb = await this.openOrgDB();
        if (!orgDb) return;

        try {
            const trans = orgDb.transaction(fieldName);
            let store = index && query ? trans.store.index(index) : trans.store;
            return await store.get(query);
        } catch (e) {
            console.error('Fail get DB data', e);
            return null;
        }
    };

    getListData = async ({ fieldName, index, query }) => {
        const orgDb = await this.openOrgDB();
        if (!orgDb) return;

        try {
            const trans = this.orgDb.transaction(fieldName);
            let store = index && query ? trans.store.index(index) : trans.store;
            return await store.getAll(query);
        } catch (e) {
            console.error('Fail get DB list', e);
            return null;
        }
    };

    saveList = async (list, fieldName, index, query) => {
        if (!this.orgDb) return;

        try {
            const trans = this.orgDb.transaction(fieldName, 'readwrite', this.transactionOptions);
            let store = index && query ? trans.store.index(index) : trans.store;

            if (!index && !query) {
                await store.clear();
            } else {
                const keysToDelete = await store.getAllKeys(query);
                for (const key of keysToDelete) trans.store.delete(key);
            }

            list.forEach((item) => trans.store.put(item));
            await trans.done;
        } catch (error) {
            console.error('Fail saveList', { error, fieldName, index, query });
        }
    };

    getOrganization = async (orgId) => {
        try {
            const organizationsStore = this.userDb.transaction('organizations').objectStore('organizations');
            return await organizationsStore.get(orgId);
        } catch (error) {
            console.error(error);
            return undefined;
        }
    };

    saveOrganization = async (organization) => {
        if (!this.userDb) return;

        try {
            const trans = this.userDb.transaction('organizations', 'readwrite', this.transactionOptions);
            trans.store.put(organization);
            await trans.done;
        } catch (error) {
            console.error(error);
        }
    };

    updateOrganization = async (data) => {
        if (!this.userDb) return;

        const organization = await this.getOrganization(data.id);
        if (!organization) return;

        try {
            const trans = this.userDb.transaction('organizations', 'readwrite', this.transactionOptions);
            trans.store.put({ ...organization, ...data });
            await trans.done;
        } catch (error) {
            console.error(error);
        }
    };

    saveMeta = async (version) => {
        if (!this.orgDb) return;

        try {
            const trans = this.userDb.transaction('meta', 'readwrite', this.transactionOptions);
            trans.store.put({ version: version, updatedAt: new Date() }, 'meta');
            await trans.done;
        } catch (error) {
            console.error(error);
        }
    };

    /**
     * Update data in single row if exist
     *
     * @param {Object} item
     * @param {ORG_DB_STORES} store
     * @return {Promise<void>}
     */
    updateCurrentRowById = async (item, store) => {
        const data = await this.getData({ fieldName: store, query: item.id });
        if (data) {
            await this.updateRowDB({ ...data, ...item }, store);
        }
    };

    /**
     * Save single data inside DB
     *
     * @param {Object} row
     * @param {ORG_DB_STORES} store
     * @return {Promise<void>}
     */
    updateRowDB = async (row, store = ORG_DB_STORES.issues) => {
        if (!this.orgDb) return;

        try {
            const trans = this.orgDb.transaction(store, 'readwrite', this.transactionOptions);
            trans.store.put(row);
            await trans.done;
        } catch (error) {
            console.error(error);
        }
    };

    removeRowDB = async (key, store = 'issues') => {
        if (!this.orgDb) return;

        try {
            const trans = this.orgDb.transaction(store, 'readwrite', this.transactionOptions);
            trans.store.delete(key);
            await trans.done;
        } catch (error) {
            console.error(error);
        }
    };
}
