import React from 'react';

import { observer } from 'mobx-react-lite';

import { mainStore } from 'store/models/MainStore';
import { utilsStore } from 'store/models/UtilsStore';

import logEvent from 'utils/logEvent';

import { CustomIcon, CustomIconName } from 'components/CustomIcon';
import Flex from 'components/Flex';
import Space from 'components/Space';
import Stripe from 'components/Stripe';

import { BlockTitle } from './components/BlockTitle';
import SkippedUsers from './components/SkippedUsers';
import DropVotesButton from './DropVotesButton';
import { ScoreTable } from './ScoreTable';

function FinalScore({ issue }) {
    const show = utilsStore.settings.alignmentOpen;

    function toggleSection() {
        !utilsStore.settings.alignmentOpen && logEvent('Open Issue Alignment');
        utilsStore.updateSettings({ field: 'alignmentOpen', value: !utilsStore.settings.alignmentOpen });
    }

    const hasFooter = issue.skippedUsersIds.length > 0 || mainStore.currentUser?.isAdmin;

    let mainContent;
    if (!issue.board) {
        return null;
    } else if (issue.board.hideScores) {
        const date = issue.board.revealDateString;
        mainContent = (
            <>
                <Space height={8} />
                <Flex gap={10}>
                    <CustomIcon icon={CustomIconName.EYE_SLASH} size={24} />
                    <h2>Nobody can see estimates till {date}</h2>
                </Flex>
                <Space height={8} />
                <p>
                    Due Evaluation Poker all estimates can be kept private until Evaluation period has ended
                    <br />
                    Nobody can see team’s evaluation results, except facilitators.
                    <br />
                    All estimates become available on Reveal date {date} and everybody will see them.
                </p>
            </>
        );
    } else {
        const disableButton = !issue.externalData?.usersAlignmentIds?.size;

        mainContent = (
            <>
                <Space height={2} />

                <div className="b-oa">
                    <ScoreTable issue={issue} />
                </div>

                {hasFooter && (
                    <Flex gap={8} style={{ padding: '8px 0' }} align="start">
                        <div className="flex-grow">
                            <SkippedUsers issue={issue} />
                        </div>

                        <Space width={20} />

                        {mainStore.currentUser?.isAdmin && (
                            <DropVotesButton
                                disabled={disableButton || issue.board?.disableEvaluation}
                                onClick={() => issue.dropVotes()}
                            />
                        )}
                    </Flex>
                )}
            </>
        );
    }

    return (
        <Stripe initialOpen={show} title={<BlockTitle issue={issue} />} onClick={toggleSection}>
            {mainContent}
        </Stripe>
    );
}

export default observer(FinalScore);
